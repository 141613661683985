<template>
	<div :style="getAppStyle()" class='BMadbreak1' :title="getTitle()" >
		<div style="font-size: 8pt; font-weight: normal;" :class="getClass()">
					
			<GRate v-if="daySlot.grp" :value="daySlot.grp" :color="options.grpColor" :max="maxGRP" style="width: 100%;">
				 <div :style='"text-align: center; color: "+getFG()+"; white-space: nowrap; display: inline-block; width: 100% !important;"'>
					<div v-if="daySlot.spotCount.bookingsOfPlacement" style='font-size: 8pt;display: inline-block;'><b>{{daySlot.spotCount.bookingsOfPlacement}}</b></div>
					<div v-if="options.showFrees && daySlot.spotCount.freeSpots" :style="'font-size: 8pt;display: inline-block; color: '+options.freeColor+' !important;'">({{daySlot.spotCount.freeSpots}})</div>
					<div v-if="options.showUnplaced && daySlot.spotCount.unplacedOfPlacement" :style="'font-size: 8pt;display: inline-block; color: '+options.unplacedColor+' !important;'">({{daySlot.spotCount.unplacedOfPlacement}})</div>
				</div>
			</GRate>
			<div v-else style="text-align: center; white-space: nowrap; display: inline-block; width: 100% !important;">
				<div v-if="daySlot.spotCount.bookingsOfPlacement" style='font-size: 8pt;display: inline-block;'><b>{{daySlot.spotCount.bookingsOfPlacement}}</b></div>
				<div v-if="options.showFrees && daySlot.spotCount.freeSpots" :style="'font-size: 8pt;display: inline-block; color: '+options.freeColor+' !important;'">({{daySlot.spotCount.freeSpots}})</div>
				<div v-if="options.showUnplaced && daySlot.spotCount.unplacedOfPlacement" :style="'font-size: 8pt;display: inline-block; color: '+options.unplacedColor+' !important;'">({{daySlot.spotCount.unplacedOfPlacement}})</div>
			</div>
		</div>
   </div>
</template>
<script>
import { getAppStyle, isDarkMode, getFG } from '@/AppStyle.js';
import GRate from '@/components/GRate';
export default {
  props: {
	  daySlot: { type: Object },
	  maxGRP: { type: Number, default: 0.2 },
	  exclusion: { type: String, default: null },
	  clashCode: { type: String, default: null },
	  forDuration: { type: Number, default: 10 },
	  options: { type: Object }
  },
 components : {
	 GRate
  },
  data () {
    return {
    	getAppStyle, getFG
    }
  },
 methods: {
   getClass()
   {
	if ( this.exclusion || this.daySlot.disabled)
	{
		return "hatchRed" +((isDarkMode())?"DM":"");
	}
	if ( this.clashCode && this.forDuration > this.daySlot.available)
	{
		return "hatchClashOver"+((isDarkMode())?"DM":"");
	}
	if ( this.clashCode )
	{
		return "hatch"+((isDarkMode())?"DM":"");
	}
	if ( this.daySlot.available < 0)
	{
		return "hatchMinus"+((isDarkMode())?"DM":"");
	}
	if ( this.forDuration > this.daySlot.available )
	{
		return "hatchOver"+((isDarkMode())?"DM":"");
	}
	return "";
   },	
   getTitle()
   {
		let title = "";
		if ( this.exclusion )
		{
			title += "Exclusion: "+this.exclusion+", ";
		}
		if ( this.clashCode )
		{
			title += "ClashCode: "+this.clashCode+", ";
		}
		title += this.daySlot.program+", Ratecard: "+this.daySlot.price+ ", Available: "+ this.daySlot.available+"sec"
		if ( this.daySlot.grp )
		{
			title += ", GRP: "+(Math.round( this.daySlot.grp*1000)/1000);
		}
		
		return title;
   }
 }
} 
</script>
<style lang="scss" scoped>
.BMadbreak1 {
	display: inline-flex; 
	width: 66%;
	max-height: 12pt;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 0pt;
    height: 100%;

}

.hatch
{
	background: repeating-linear-gradient( 45deg, #ccc, #ccc 2px, white 2px, white 5px);
}
.hatchClashOver
{
	background: repeating-linear-gradient( 45deg, #f99, #f99 2px, white 2px, white 5px);
}
.hatchOver
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 5px);
}
.hatchMinus
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 3px);
}
.hatchRed
{
	background: repeating-linear-gradient(-45deg, #f99, #f99 2px, white 2px, white 6px);
}

.hatchDM
{
	background: repeating-linear-gradient( 45deg, #888, #444 2px, black 2px, black 5px);
}
.hatchClashOverDM
{
	background: repeating-linear-gradient( 45deg, #f44, #f44 2px, black 2px, black 5px);
}
.hatchOverDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 5px);
}
.hatchMinusDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 3px);
}
.hatchRedDM
{
	background: repeating-linear-gradient(-45deg, #f44, #f44 2px, black 2px, black 6px);
}
</style>