<template>
 <div style='margin: 0pt; height: 100%; width:100%;'>
 
  <div style='width: 100%;'>
     <div style='width: 100%;'>
       <div class="xmodal-header">
          <slot name="header">
            <div class='appHeadLine' :style='getAppStyle()'>
            
            	<div v-if='placementIDs.length === 1' class='appLeft'>
            	Spot-List {{placement.name}} 
                </div>
                <div v-else class='appLeft'>
            	Spot-List ({{placementIDs.length}} placements)
                </div>
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div>
          <slot name="body">
            <div style='float: top; width: 100%;' >
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->				
			    <div style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
			    <div class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
		              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b> <span :class='getDisplayedClass()' :title="getDisplayedSpots()">{{filteredSpots.length}}</span> / <span title='Filtered by Dates'>{{filteredByDate}}</span> / <span title='Total'>{{spots.length}}</span></span>
		        </div>
			    
			    <span class='dontWrap smallText bold'>Channel/Media:</span>
			    <vSelect :options="allMedia" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedMedia" :multiple="true" @input="processChange()">  
		        </vSelect>
		        <!--
		        <span class='dontWrap smallText bold'>Grid/Programs:</span>
			    <vSelect :options="allPrograms" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedPrograms" :multiple="true" @input="processChange()">  
		        </vSelect>
				-->
		        <span class='dontWrap smallText bold'>Pricegroups:</span>
			    <vSelect :options="allPGs" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedPGs" :multiple="true" @input="processChange()">  
		        </vSelect>
		        
		        <span class='dontWrap smallText bold'>Products:</span>
			    <vSelect :options="allProducts" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedProducts" :multiple="true" @input="processChange()">  
	
		        </vSelect>
		        <span class='dontWrap smallText bold'>Durations:</span>
			    <vSelect :options="allDurations" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedDurations" :multiple="true" @input="processChange()">  
	
		        </vSelect>
		        
		        <span class='dontWrap smallText bold'>Business type:</span>
			    <vSelect :options="allTypes" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedTypes" :multiple="true" @input="processChange()">  
	
		        </vSelect>
		        
		        <span class='dontWrap smallText bold'>Copies:</span>
			    <vSelect :options="allCopies" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedCopies" :multiple="true" @input="processChange()">  
	
		        </vSelect>
		        
		        <span class='dontWrap smallText bold'>Status:</span>
			    <vSelect :options="allStatus" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedStatus" :multiple="true" @input="processChange()">  
		        </vSelect>
		        <!--
		        <div v-show="invalidSpotsFound">
		        <span class='dontWrap smallText bold'>Invalid Spots:</span>
			    <vSelect :options="allSpotReasons" :style="(isDarkMode()?'border: 1px solid grey;':'')+ 'font-size: 9pt;'" v-model="selectedSpotReasons" :multiple="true" @input="processChange()">  
			
		        </vSelect>
				</div>    
				-->
				<span v-show="filteredSpots.length >= 100">
				<span class='dontWrap smallText bold'>Spots displayed:</span>
				<vue-slider :marks=false min=50 :max="filteredSpots.length" v-model="limitSpots"></vue-slider>	
				<br/>
				</span>
				<!--
				<br/>
		        <button class='button myButton' @click='showColumnSelect($event)'>Configure columns</button>
			 	-->
				
				<div  style="max-height: 100pt; overflow-y: auto; padding: 5pt;">
					<div v-for="(r,ri) in getMarkedSpots()" :key="'ms'+ri" style="font-size: 9pt;">
						<table width="100%">
							<tr><td></td><td class="right">Marked</td><td class="right">Paid</td><td class="right">Free</td><td class="right light">Total</td></tr>
							<tr><td>Spots</td><td class="right">{{r.count}}</td><td class="right">{{r.paid.count}}</td><td class="right">{{r.free.count}}</td><td class="light right">{{r.total.count}}</td></tr>
							<tr><td>Gross1</td><td class="right">{{formatNumber( r.gross1)}}</td><td class="right">{{formatNumber( r.paid.gross1)}}</td><td class="right">{{formatNumber( r.free.gross2)}}</td><td class="light right">{{formatNumber( r.total.gross1)}}</td></tr>
							<tr><td>Gross3</td><td class="right">{{formatNumber( r.gross2)}}</td><td class="right">{{formatNumber( r.paid.gross3)}}</td><td class="right">{{formatNumber( r.free.gross3)}}</td><td class="light right">{{formatNumber( r.total.gross3)}}</td></tr>
							<tr><td>Gross5</td><td class="right">{{formatNumber( r.gross2)}}</td><td class="right">{{formatNumber( r.paid.gross5)}}</td><td class="right">{{formatNumber( r.free.gross5)}}</td><td class="light right">{{formatNumber( r.total.gross5)}}</td></tr>
							<tr><td>Volume</td><td class="right">{{printTime(r.duration, ":", true, true)}}</td><td class="right">{{printTime(r.paid.duration, ":", true, true)}}</td><td class="right">{{printTime(r.free.duration, ":", true, true)}}</td><td class="light right">{{printTime(r.total.duration, ":", true, true)}}</td></tr>					
						</table>
					</div>	
				</div>
				

				<div v-show="marked && marked.length" class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
		              <span class='BMSectionDetail dontWrap90 smallText'><b>ACTIONS</b></span>
		        </div>
				<span v-if="placementIDs.length < 2">
					<span v-show="marked && marked.length">
			        	<button :style="getAppStyle()" @click='rebookSpots(event, null, "rebookSpots")' class='button myButton'>Rebook ({{marked.length}})</button>
			        </span>
			        
			        <span v-show="copyRotation && copyRotation.id && marked && marked.length">
			        	<button :style="getAppStyle()" @click='rebookSpots(event, null, "runCopyRotation")' class='button myButton'>Run rotation ({{marked.length}})</button>
			        </span>
					
					<button :style="getAppStyle()" title="show copy distribution" v-show="marked && marked.length" class="button myButton"  @click="getCopyChart()">%</button>
		        </span>
		        <span v-else title='select single placement in the context-menu'>
					<span v-show="marked && marked.length">
			        	<button :style="getAppStyle()" disabled @click='rebookSpots(event, null, "rebookSpots")' class='button myButton'>Rebook ({{marked.length}})</button>
			        </span>
			        
			        <span v-show="copyRotation && copyRotation.id && marked && marked.length">
			        	<button :style="getAppStyle()" disabled @click='rebookSpots(event, null, "runCopyRotation")' class='button myButton'>Run rotation ({{marked.length}})</button>
			        </span>
					
					
		        </span>
				
				<div v-if="placementInfo.placementStati && placementInfo.placementStati.length">
					<button :style="getAppStyle()" class='button myButton' v-for="(st,sti) in placementInfo.placementStati" :key="'bn'+sti" @click="switchTo(st)"><mdicon name="transfer-right"/>{{st.name}}</button>
				</div>	
				
			    
			    </div> <!-- CONTROLS -->
				
				<div v-show="marked && marked.length && chart" :style="getAppStyle()+'width: 100%; font-size: 8px; height: calc(25vH) !important; overflow-y: hidden;'">
				 	<div :style="getAppStyle()+'width: 100%;  font-size: 8px;'">
				 	
				 	        <div style="color: #000;" id='aChart' />
					       
					 </div>
				</div>
									
    		</div>
    
    		<!-- MAIN -->
            <div class='transition'  style='float: left; width: 55%;'> 
			    <!-- HEADER -->
			    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'ColSp_'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        @dragstart="startDragColumn($event, col.show, idx)" draggable
					        @drop="onDropColumn($event, idx)"
					        @dragover.prevent
							@dragenter.prevent
							@click='setOrderBy(col)'
							@contextmenu='showColumnSelect($event)'>
					       <div class='header higher' :style='getSpotDetailStyle(col)'>
					       		<div v-show="!col.buttons"> {{ col.name }}<sub>{{getOrderBy(col)}}</sub></div>
					       	</div>
					    </div>
					   
				    </div>  <!-- float: top -->   
				</div> <!-- HEADER -->
				<div style='width: 100%; height: calc(75vH); overflow-y: scroll;'>
			    	<div style='float: top; width: 100%;height: 100%;'>
			    	    <div v-for="(spot,idx) in limit(filteredSpots)" :key="'SPT_'+idx" :id="'SPT_'+idx"
			    	     @mouseenter="hoverLine('SPT_'+idx)"
			             @mouseleave="leaveLine('SPT_'+idx)"
			    	    >
			    	
			    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" class='floatLeft' @contextmenu='spotRClicked($event, spot)' :style='getColumnStyle(col,spot)'>
						        <div v-if="col.buttons" class='spotDetail'>
						       		<button @click='deleteSpot(spot)' class='iButton'>
						       		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#aaa" class="bi bi-trash" viewBox="0 0 16 16">
									  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
									  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
									</svg>
						       		</button>
						       	</div>
							  <div v-else-if="spot.statusInPlaylist && !spot.gross5" class='spotDetail dontWrap' :style="getSpotDetailStyle(col)+';color:'+options.freeColor+';'"
						            v-on:click.shift='markUntil($event, spot, idx)'
						            v-on:click.shift.prevent
						            v-on:click.exact='mark($event, spot, idx)'>
						       		{{ print(spot, col) }}
						       	</div>
						       <div v-else-if="spot.statusInPlaylist" class='spotDetail dontWrap' :style='getSpotDetailStyle(col)' 
						            v-on:click.shift='markUntil($event, spot, idx)'
						            v-on:click.shift.prevent
						            v-on:click.exact='mark($event, spot, idx)'>
						       		{{ print(spot, col) }}
						       	</div>
						       	<div v-else class='spotDetail dontWrap' :style="getSpotDetailStyle(col)+';color:'+options.unplacedColor+';'" 
						            v-on:click.shift='markUntil($event, spot, idx)'
						            v-on:click.shift.prevent
						            v-on:click.exact='mark($event, spot, idx)'>
						       		{{ print(spot, col) }}
						       	</div>
						    </div> 

			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->
            </div> <!-- float: left -->
			</div> <!-- mainContent -->
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  
	 </div> <!-- modal-wrapper -->  	
	 <ContextMenu ref="menu" offsetY="0">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="openBreak(event, contextData)">
          Open Break 
        </ContextMenuItem>
        <ContextMenuItem v-if="placementIDs.length > 2"  @clicked="setPlacement(event, contextData)">
          Use Placement {{contextData.placementName}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="markAll(event)">
          Mark all
        </ContextMenuItem>
        <ContextMenuItem  @clicked="toggleMarks(event)">
          Toggle marks
        </ContextMenuItem>
        
        <ContextMenuSep/>
        <ContextMenuItem v-if="placementIDs.length < 2"  @clicked="rebookSpots(event, contextData, 'rebookSpots')">
          Rebook marked Spots
        </ContextMenuItem>
        <ContextMenuItem  @clicked="deleteSpot(event)">
          Cancel marked Spots
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.copyId" @clicked="removeCopy(event, contextData)">
         Remove copy
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.gross5" @clicked="setPrice(event, 0, contextData)">
          Set price to 0
        </ContextMenuItem>
		
        <ContextMenuItem  v-if="contextData.gross4 || (contextData.gross4 == contextData.gross5)"  @clicked="setPrice(event, -999, contextData)">
          Remove Fixed price
        </ContextMenuItem>
        <ContextMenuItem  v-if="contextData.rotationId"  @clicked="rebookSpots(event, contextData, 'removeRotation')">
          Remove Copy Rotation
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="!placementIDs.length < 2 && copyRotation && copyRotation.id" @clicked="rebookSpots(event, contextData, 'runCopyRotation')">
          Run Copy Rotation
        </ContextMenuItem>
		<ContextMenuSep/>
		<span v-for="pReq in positionRequests"  :key="'PR'+pReq.id">
			<ContextMenuItem v-if="(!contextData.positionInBreakId) || (pReq.id != contextData.positionInBreakId)" @clicked="setPositionReq(event, pReq.id, contextData)">
	          Request position {{pReq.name}}
	        </ContextMenuItem>
		</span>	
		

		<ContextMenuItem v-if="contextData.positionInBreakId" @clicked="setPositionReq(event, -1, contextData)">
		  Remove position request {{positionRequests.find(p=>p.id==contextData.positionInBreakId).name}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <span v-for="stati in bookingTypes"  :key='stati.id'>
        <ContextMenuItem v-if="stati.id!=contextData.statusId" @clicked="changeType(event, stati, contextData)">
          {{ stati.name }}
        </ContextMenuItem>
        </span>
       </template>
    </ContextMenu>
    <BreakView ref='breakView' :reference=selectedRef :time="new Date().getTime()" 
        :options=options
        @saveUser=saveUser
        @deleteSpot="deleteSpot"
        @setTypeForSpots="setTypeForSpots"
        @close="closeBreakView()">
    </BreakView>
    
    <ColumnSelector v-show='showColumnSelector' 
        :availableColumns=availableColumns
        :selected=selected
 
		@selectedColumns=setSelectedColumns
        @close="showColumnSelector=false">
        
    </ColumnSelector>
	 <GFWEditorRouter v-show='showEditor' @update="update"  @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>  
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, bngAPI, setReload, myLocale, formatNumber, showError } from '@/variables.js';
import { getAppStyle, setDarkMode, isDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import BreakView from '@/components/BreakView';
import ColumnSelector from '@/components/ColumnSelector';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
import ApexCharts from 'apexcharts'
var momentTZ = require('moment-timezone');
let $ = JQuery;
var timers = [];
var filteredSpotsTMP;
var lastMarked = -1;
export default {
  name: 'GFW Spot-List View',
  components : {
   ContextMenu,ContextMenuItem, ContextMenuSep, BreakView, vSelect, ColumnSelector
  },
  props: {
    reference: String,
    placement: Object,
    placementIDs: Array,
	placementInfo: Object,
    campaign: Object,
    placementID: Number,
    spotSearchFilter: Object,
    inputWeekdays: {type: Number, defaultValue: 127},
    inputBreakTypes: Array,
    inputDateFrom: Date,
    inputDateTo: Date,
    preSelectMedia: Array,
    copyRotation: Object,
    options: Object,
    deletedSpots: Array,
    updatedSpots: Array,
    time: Number,
    display: Boolean
  },
  data () {
    return {
        gridAds: {},
        spots: [],
        filteredSpots: [],
        filteredByDates: 0,
		width: 100,
        height: 100,
        mainHeight: 100,
        bookingTypes: [],
		positionRequests: [],
        unit: 15,
        zoom: 15,
        limitSpots: 300,
        zoomArr: [10,12,14,15,16,18,20,22],
        grid: [],
        weekdayLabel: [],
        withAds: true,
        starttime: 0,
        dayStartAt: 0,
        controlsRight: false,
        mapping: [],
        metadata: {},
        dataId: 0,
        breakData: {},
        user: {},
        //
        allMedia: [],
        selectedMedia: [],
        allPrograms: [],
        selectedPrograms: [],
        allPGs: [],
        selectedPGs: [],
        allStatus: [],
        selectedStatus: [],
        allCopies: [],
        selectedCopies: [],
        allTypes: [],
        selectedTypes: [],
        allDurations: [],
        selectedDurations: [],
        allProducts: [],
        selectedProducts: [],
        selectedBreakTypes: [],
        selectedWeekdays: [],
        allSpotReasons: [],
        selectedSpotReasons: [],
        invalidSpotsFound: 0,
        selectedDateTo: {},
        selectedDateFrom: {},
        //
        //fontSize: this.options.fontSize,
        loader: {},
        loadingActive: false,
        //
        weekdayArray: [],
        selectedRecord: {},
        selectedIndex: 0,
        showBreakView: false,
        selectedRef: "",
        allEntities: [],
        showEditor: false,
        breakReference: {},
        widthCalculated: 100,
        avColumnsVisible: 0,
        orderBy: [],
        marked: [],
        showColumnSelector: false,
		chart: null,
		formatNumber,

        getAppStyle,
        getBGStyle2,
		isDarkMode,
        
        availableColumns: [
	        {name: "Pos", show: "position", w: 3, align: 'R'},
	        {name: "Date", show: "financialDate",  date: true, w: 9, align: 'L'},
			{name: "TXDate", show: "date", date: true, w: 9, align: 'L'},
	        {name: "WeekDay", show: "weekday", weekday: true, w: 9, align: 'C'},
	        {name: "Program", show: "gridName", w: 30, align: 'L'},
	        {name: "Rerun", show: "gridIsRerun", boolean:true, w: 5, align: 'L'},
	        {name: "Gridtime", show: "gridTime", time:true, w: 8, align: 'C'},
	        {name: "Time", show: "time", time:true, w: 8, align: 'C'},
	        {name: "TimeSlot", show: "gridTimeSlot", w: 8, align: 'C'},
	        
	        {name: "Break", show: "breakTypeShort", w: 10, align: 'C'},
			{name: "BreakLabel", show: "breakCode", w: 10, align: 'C'},
	        {name: "id", show: "id", w: 8, align: 'R'},
	        {name: "replacingId", show: "replacingSpotId", w: 8, align: 'R'},
	        {name: "mainId", show: "mainSpotId", w: 8, align: 'R'},
	        {name: "Break#", show: "breakNo", w: 5, align: 'R'},
	        {name: "Channel", show: "channelShort", w: 10, align: 'L'},
	        {name: "PG", show: "pricegroup", w: 5, align: 'R'},
	        {name: "Product", show: "productName", w: 15, align: 'L'},
	        {name: "BusinessType", show: "businessType", w: 15, align: 'L'},
	        {name: "ProductFamily", show: "productFamily", w: 15, align: 'C'},
	        {name: "ProductGroup", show: "productGroup", w: 15, align: 'C'},
	        {name: "Dur", show: "duration", w: 5, align: 'R'},
	        {name: "Copy", show: "copyName", w: 25, align: 'L'},
	        {name: "Rotation", show: "rotationName", w: 20, align: 'L'},
	        {name: "HouseNumber", show: "copyHouseNumber", w: 15, align: 'L' },
	        {name: "Placement", show: "placementName", w: 20, align: 'L' },
	        {name: "Code", show: "copyCode", w: 15, align: 'L' },
	        {name: "Currency", show: "currency", w: 5, align: 'L' },
	        {name: "Gross", show: "gross5", w: 10, align: 'R' },
	        {name: "Gross"+sessionStorage.hCurrency, show: "grossHC", w: 10, align: 'R' },
	        {name: "Net", show: "net", w: 10, align: 'R' },
	        {name: "Fixed", show: "gross4", w: 10, align: 'L' },
	        {name: "GRP", show: "grp", w: 5, align: 'R' },
	        {name: "Ratecard", show: "gross3", w: 10, align: 'R' },
	        {name: "Ratecard 30", show: "gross1", w: 10, align: 'R' },
	        {name: "PosRQ", show: "positionRequest", w: 5, align: 'L' },
	        {name: "Status", show: "statusName", w: 10, align: 'L' },
	        {name: "SalesUnit", show: "salesUnit", w: 10, align: 'L' },
	        {name: "Created", show: "created", w: 10, align: 'L' },
	        {name: "Valid", show: "statusValid", boolean: true, w: 10, align: 'L' },
	       
	        {name: "InPlaylist", show: "statusInPlaylist", boolean: true, w: 10, align: 'L' },
	        {name: "Gross", show: "gross5", w: 10, align: 'R' },
	        {name: "Invalid", show: "invalid", boolean: true, w: 5, align: 'L' },
	        {name: "Reason", show: "reason4Invalid", w: 20, align: 'L' },
	        {name: "(Action)", show: "-", w: 5, buttons: true, align: 'C' }
        
        ],
        selected: ["Pos", "Channel", "Date", "Program", "Break", "BreakNo", "Copy", "Gross", "(Action)"],
        selectedColumns: [
	        
        ]
    }
  },
  /////
 
  /////
  methods: {
     getAvColumnClasses()  { if(!this.avColumnsVisible) { return "invisible transition" } else { return "choice normal floatLeft button transition"}},
     getContentStyle()  { return "width: 100% !important;height: "+ this.height+"% !important;"; },
     myLocale() { return myLocale();},
     getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex; padding-top: 0;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex; padding-top: 0;"
     },
     setSelectedColumns( sc ) { this.selectedColumns = sc; this.calcWidths( true ) },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
     close() { this.breakReference = null; this.$emit("close"); },
     gridHeight() { return "height: "+this.zoom+"pt;";},
     hasTimezone() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasGenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasSubgenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     identity(x) { return x },
     setAvColumnsVisible( val) { this.avColumnsVisible = val},
     getDisplayedSpots() { return ( this.filteredSpots.length < this.limitSpots ) ? "Displayed Spots" : "Only " + this.limitSpots + " displayed" ; },
     getDisplayedClass() { return ( this.filteredSpots.length > this.limitSpots ) ? "displayRed bold colored":""; },
	 getMarkedSpots()
	 {
		let spots = this.spots.filter(p=>this.marked.includes( p.id));
		let result = {
						count: 0, gross1: 0, gross2: 0, gross3: 0, gross4: 0, gross5: 0, duration: 0,
						paid: { count: 0, gross1: 0, gross2: 0, gross3: 0, gross4: 0, gross5: 0, duration: 0 },
						free: { count: 0, gross1: 0, gross2: 0, gross3: 0, gross4: 0, gross5: 0, duration: 0 },
						total: { count: 0, gross1: 0, gross2: 0, gross3: 0, gross4: 0, gross5: 0, duration: 0 },
					};
		for ( let si in this.spots)
		{
			let s = this.spots[si];
			result.total.count++;
			result.total.gross1 += s.gross1;
			result.total.gross2 += s.gross2;
			result.total.gross3 += s.gross3;
			result.total.gross4 += s.gross4;
			result.total.gross5 += s.gross5;
			result.total.duration += s.duration;
		}			
		if ( spots && spots.length )
		{
			
			for ( let si in spots)
			{
				let s = spots[si];
				result.count++;
				result.gross1 += s.gross1;
				result.gross2 += s.gross2;
				result.gross3 += s.gross3;
				result.gross4 += s.gross4;
				result.gross5 += s.gross5;
				result.duration += s.duration;
				let type = ( s.gross5 > 0.001) ? result.paid : result.free;
				type.count++;
				type.gross1 += s.gross1;
				type.gross2 += s.gross2;
				type.gross3 += s.gross3;
				type.gross4 += s.gross4;
				type.gross5 += s.gross5;
				type.duration += s.duration;
			}
			
		}
		return [ result ];
	 },
	 getCopyChart( refresh)
     {
		if ( !refresh && this.chart )
		{
			this.distroyChart();
			return;
		}
    	let col = sessionStorage.fgColor;
		let spots = this.limit(this.filteredSpots).filter( p=>this.marked.includes(p.id))
		let copyArr = spots.map(p=>p.copyId);
		let copySet = [];
		for ( let c in copyArr )
		{
			let copy = copyArr[c];
			if ( !copySet.includes( copy))
			{
				copySet.push( copy);	
			}
		}
		
		if ( !copySet.length)
		{
			return;
		}
		let copyCount = [];
		let copyNames = [];
		for ( let c in copySet )
		{
			let copy = copySet[c];
			copyCount[c] = spots.filter(p=>p.copyId==copy).length;
			copyNames[c] = spots.find(p=>p.copyId==copy).copyName;
			if ( copyNames[c].length > 8)
			{
				copyNames[c] = copyNames[c].substring(0,7);
			}
		}
		
		
    	let chartOptions = {
				  chart: { 
				    type: 'pie',
    				     animations: {easing: 'easeinout', speed: 400, animateGradually: { enabled: true }}
				  },
				  
				  series: copyCount,
				  size: 200,
				  markers: {
					  size: [4, 7]
					  
					},
					pie: {
				        customScale: 3.8
				      },
				      legend: {
				          
				          labels: {
				              colors: [col] 
				          }
				      },
				  stroke: {
					  width: 2
					},
					dataLabels: {
		  				  enabled: true,
		  				dropShadow: {
		  					enabled: false
		  				},
		  				    style: {
		  				      colors: ['#000']
		  				    }
		  				
		  				  },
					labels: copyNames
				}
		this.distroyChart();
		this.chart = new ApexCharts(document.querySelector("#aChart"), chartOptions);
		this.chart.render();
    },
	distroyChart()
    {
    	if ( this.chart)
		{
    		try
    		{
				this.chart.destroy();
				this.chart = null;
			}
    		catch(e)
    		{
    		//
    		}
		}
    },		
	 switchTo( st )
	 {
		this.$emit("switchTo", st)
	 },
     spotRClicked(event, spot)
     {
        event.preventDefault();
        this.$refs.menu.open(event, spot )
     },
     hoverLine(line)
     {
    	$('#'+line).children().addClass('reqLineBG');
     },
     leaveLine(line)
     {
    	$('#'+line).children().removeClass('reqLineBG');
     },
     showColumnSelect(event)
     {
        event.preventDefault();
     	this.showColumnSelector=true;
     },
     openBreak( event, spot)
     {
        this.selectedRef = this.getRawKey(spot);
    	this.$refs.menu.close();
    	this.$refs.breakView.open(this.selectedRef);
     },
     setPlacement( event, spot)
     {
        this.$emit("placementId", spot.placementId, this.spotSearchFilter)
     },
     getSpotDetailStyle(col)
     {
        if ( this.loadingActive )
        {
        	return "color: #fff; font-size:"+this.options.fontSize+"px";
        }
        let sty = "font-size:"+this.options.fontSize+"px;"
        if ( col.align == 'R' )
        {
        	sty += "justify-content: right;";
        }
        else if ( col.align == 'L' )
        {
        	sty += "justify-content: left;";
        }
        else
        {
        	sty += "justify-content: center;";
        }
        // justify-content: right;
     	return sty;
     },
     getRawKey(spot)
     {
    	return spot.gridId+":"+spot.mediaId+":"+spot.breakNo+":"+spot.breakTypeId+":"+spot.date;
     },
     closeBreakView() {
    	this.showBreakView = false;
     },
     setPrice( event, price, spot)
     {
     	try { this.$refs.menu.close(); } catch (e) { /* */ } 
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	    }
     	//alert("emit--> " + mySpots.length)
     	this.$emit("setPrice", mySpots, price);
     },
	 setPositionReq( event, posId, spot)
     {
      	try { this.$refs.menu.close(); } catch (e) { /* */ } 
      	var mySpots = new Array();
      	if ( this.marked.length == 0 && spot )
      	{
      		mySpots.push(spot);
      	}
      	else
      	{
 	     	for ( var i in this.filteredSpots )
 	     	{
 	     		let spot = this.filteredSpots[i];
 	     		for ( var s in this.marked )
 	     		{
 	     			if (this.marked[s] === spot.id)
 	     			{
 	     				mySpots.push(spot);
 	     				break;
 	     			}
 	     		}
 	     	}
 	    }
      	//alert("emit--> " + mySpots.length)
      	this.$emit("setPositionReq", mySpots, posId);
     },
     removeCopy( event, spot )
     {
     	try { this.$refs.menu.close(); } catch (e) { /* */ } 
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	     }
     	//alert("emit--> " + mySpots.length)
     	this.$emit("removeCopy", mySpots);
     },
     limit(spots)
     {
     	let maxLen = this.limitSpots;
        if ( spots.length > maxLen )
        {
        	return spots.slice().splice( 0, maxLen );
        }
        return spots;
     },
     rebookSpots(event, spot, doWhat)
     {
        try { this.$refs.menu.close(); } catch (e) { /* */ } 
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	     }
     	//alert("emit--> " + doWhat+"-->"+mySpots.length)
     	this.$emit( doWhat, mySpots);
     },
    
     changeType(event, type, spot)
     {
        try {
        this.$refs.menu.close();
        } catch (e) { 
        //
        } 
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	    }
     	this.changeTypePassThrough(mySpots, type);
     	
     },
     changeTypePassThrough(mySpots, type)
     {
     	this.$emit("setTypeForSpots", mySpots, type);	
     },
     getMarked(spot) 
     { 
     	if (spot && this.marked.includes(spot.id)) 
     	{
     		return "background-color: #888; color: #fff;"
     	} 
     	return ""; 
     	//return "background-color: #fff;";
     },
     getColumnStyle(col, spot)
     {
        return "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%"+";"+this.getMarked(spot);
     }, 
     mark(evt, spot, idx)
     {
        // evt.target.parentNode.parentNode.style='font-weight: bold;'
        var add = true;
        lastMarked = idx;
     	for ( var s in this.marked )
     	{
     		if (this.marked[s] === spot.id)
     		{
     			this.marked.splice( s, 1);
     			add = false;
     			break;
     		}
     	}
     	if ( add )
     	{
     		this.marked.push( spot.id);
     	}
		if ( this.chart )
		{
			this.getCopyChart( true);
		}
     	//this.$forceUpdate();
     },
     markUntil(evt, spot, idx)
     {
		if ( this.chart )
		{
			this.distroyChart();
			this.chart = null;
		}
        if ( lastMarked < idx )
        {
	        for ( let i = lastMarked+1; i <= idx; i++)
	        {
	        	this.mark(evt, this.filteredSpots[i], i);
	        }
	    }
	    else if ( lastMarked > idx )
	    {
	    	for ( let i = lastMarked-1; i >= idx; i--)
	        {
	        	this.mark(evt, this.filteredSpots[i], i);
	        }
	    }
	    lastMarked = idx;
     },
     markAll()
     {
        var idArr = new Array();
        for ( var i in this.filteredSpots)
        {
        	idArr.push(this.filteredSpots[i].id);
        }
     	this.marked = idArr;
     	if ( this.chart )
		{
			this.getCopyChart( true);
		}
     },
     toggleMarks()
     {
        var idArr = new Array();
        for ( var i in this.filteredSpots)
        {
        	idArr.push(this.filteredSpots[i].id);
        }
     	this.marked =  idArr.filter(id => !this.marked.includes(id));
		if ( this.chart )
		{
			this.getCopyChart( true);
		}
     },
     checkMark()
     {
        var idArr = new Array();
        for ( var i in this.filteredSpots)
        {
        	idArr.push(this.filteredSpots[i].id);
        }
     	this.marked =  this.marked.filter(id => idArr.includes(id));
     },
     getBreakDuration(spots)
     {
     	var dur = 0;
     	for ( var s in spots )
     	{
     		dur += spots[s].duration;
     	}
     	return dur;
     },
     notSelectedColumns() // OUT
     {
     	var cols = new Array();
     	for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i].show;
     		var selected = false;
     		for ( var vis in this.selectedColumns )
     		{
     			let colSel = this.selectedColumns[ vis].show;
     			if ( colSel === col )
     			{
     				selected = true;
     				break;
     			}
     		}
     		if ( ! selected )
     		{
     			cols.push( this.availableColumns[ i]);
     		}
     	} 
     	return cols;
     },
     print(spot, col)
     {
        //return spot[ col.show ];
     	let val = spot[ col.show ];
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	if ( col.weekday )
     	{
     		return this.weekdayArray[ ((spot.weekday+5)%7)+1 ];
     	}
		if ( col.date )
     	{
     		return momentTZ( new Date( val)).format('DD.MM');
     	}
     	if ( col.time )
     	{
     		return this.printTime(val, ":", false, false);
     	}
     	return formatNumber( val );
     },
     setOrderBy( col )
     {
        var add = true;
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( col.sorting === 1 )
     		    {
     		    	col.sorting = -1;
     		    }
     		    else
     		    {
     				this.orderBy.splice( i, 1);
     				col.sorting = 0;
     			}
     			add = false;
     			break;
     		}
     	}
     	if ( add )
     	{
     		this.orderBy.push( col );
     		col.sorting = 1;
     	}
     	this.doOrderBy();
     },
     processChange()
     {
     	this.doFilterSpots();
     	this.doOrderBy();
     },
     doFilterSpots()
     {
      	let tmp = this.spots;
      	//this.startLoader();
      	if ( this.selectedWeekdays  )
     	{
     		tmp =  tmp.filter(spot => this.selectedWeekdays[(spot.weekday+5)%7] );
     	}
     	if ( this.selectedDateFrom )
     	{
     	    this.selectedDateFrom = new Date(this.inputDateFrom);
	        this.selectedDateFrom.setHours(0);
	        this.selectedDateFrom.setMinutes(0);
	        this.selectedDateFrom.setSeconds(0);
     		tmp = tmp.filter(spot => new Date(spot.date) >= this.selectedDateFrom || new Date(spot.financialDate) >= this.selectedDateFrom );
     	}
     	if ( this.selectedDateTo )
     	{
     		tmp =  tmp.filter(spot => new Date(spot.date) <= this.selectedDateTo );
     	}
     	this.filteredByDate = tmp.length;
     	if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
     	}
     	if ( this.selectedPGs && this.selectedPGs.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedPGs.includes(spot.pricegroup) );
     	}
     	if ( this.selectedStatus && this.selectedStatus.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedStatus.includes(spot.statusName) );
     	}
     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
     	}
     	if ( this.selectedDurations && this.selectedDurations.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedDurations.includes(spot.duration) );
     	}
     	if ( this.selectedTypes && this.selectedTypes.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedTypes.includes(spot.businessType) );
     	}
     	if ( this.selectedMedia && this.selectedMedia.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedMedia.includes(spot.channelShort) );
     	}
     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
     	}
     	if ( this.selectedBreakTypes && this.selectedBreakTypes.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedBreakTypes.includes(spot.breakTypeShort) );
     	}
     	if ( this.selectedSpotReasons && this.selectedSpotReasons.length > 0 )
     	{
     		tmp =  tmp.filter(spot => this.selectedSpotReasons.includes(spot.reason4Invalid) );
     	}
     	this.filteredSpotsTMP = tmp;
     	this.checkMark();
     	return this.filteredSpotsTMP;
     },
     doOrderBy()
     {
        let that = this;
     	this.filteredSpotsTMP.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( v1 > v2 )
	     		{
	     		   
	     			return iCol.sorting;
	     		}
	     		if ( v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return 0;
	     });
	     
     	this.filteredSpots = this.filteredSpotsTMP;
     },
     getOrderBy( col )
     {
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( iCol.sorting > 0 )
     		    {
     				return "A"+(parseInt(i)+1);
     			}
     			return "D"+(parseInt(i)+1);
     		}
     	}
     	return "";
     },
     getAdSpaceSection( no )
     {
     	if ( no === 0 ) { return "Section BEFORE" }
     	else if ( no === 999 ) { return "Section AFTER" }
     	else { return "Inside-Section "+no }
     },
     
     calculatePrice(dur)
     {
     	let base = this.gridAds.rateCard.price/this.gridAds.rateCard.duration;
     	var returnValue = 0;
     	for (var pi in this.gridAds.priceIndexes )
     	{
     		let index = this.gridAds.priceIndexes[pi];
     		if ( index.durFrom <= dur && index.durTo >= dur )
     		{
     			returnValue = index.index/100*base*(dur*index.factor+index.value);
     			break;
     		}
     	} 
     	if ( this.gridAds.priceIndex.rounding )
     	{
     		return Number(Math.round(returnValue)).toLocaleString(myLocale())
     	}
       return Number(Math.round(returnValue*100)/100).toLocaleString(myLocale())
     },
     openEntity( evt, entity, id )
     {
        let that = this;
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
     closeEditor()
    {
         this.showEditor = false;
    },
    update() {
       //
    },
     timezoneStyle() {
        if ( this.hasTimezone() )
        {
    		return "float: left; width: 10%  !important; background-color: #eee; color: #444;";
    	}
    	return "float: left; width: 0pt !important; background-color: #eee; color: transparent;";
    },
     weekDayIndexes() {
            var intArr = [];
            for ( var i=0; i < this.weekdayLabel.length; i++ )
            {
                intArr.push((i + parseInt(sessionStorage.weekStart)) % 7);
            }
            return intArr;
        },
     printWeekdays(wd) {
          let p2 = 1;
          var x = [];
          for ( var ii = 0; ii < 7; ii++ )
          {
            if ((wd & p2) == p2)
            {
            	x[ii] = true;
            }
            else
            {
            x[ii] = false;
            }
            p2 = p2 * 2;
          }
          var retString = "";
          //alert( x +" / "+ this.weekDayIndexes() +" // "+ this.weekdayLabel);
          let arr = this.weekDayIndexes();
          let cnt = 0;
          for ( ii in arr )
          {
            let idx = arr[ii]
          	retString += ( x[idx] ) ? this.weekdayLabel[idx]: "-";
          	if ( cnt < 6 )
          	{
          		retString += ",";
          	}
          	cnt++; 
          }
          //alert( x +" / "+ this.weekDayIndexes() +" / "+ this.weekdayLabel +" --> " + retString);
          return retString;
     },
     setValues()
      {
      	  //alert( JSON.stringify(this.gridAds));
          //console.log("set>" +this.val + " " + this.value);

          this.starttime = this.gridAds.starttime;
          this.weekdayLabel = [];
          let wdArr = JSON.parse(sessionStorage.weekdays);
          for ( var i=1; i < 8; i++ )
          {
             let x = wdArr[i];
             if ( x.length > 2 )
             {
                 x = x.substring(0,2);
             }
             this.weekdayLabel.push( x);    
          }
          //
          this.unit = this.gridAds.detail.duration / 25;
          //alert( this.gridAds.detail.duration +"-->"+this.unit+"/"+(this.gridAds.detail.duration*this.unit));
          if ( this.unit > 5 )
          {
          	this.unit = this.unit + 5 - this.unit%5;
          }
          //alert( this.gridAds.detail.duration +"-->"+this.unit+"/"+(this.gridAds.detail.duration*this.unit));
          //alert( this.gridAds.detail.duration +"-->"+this.unit);
          //alert( this.gridAds.detail.duration + this.unit);
          this.grid=[];
          this.dayStartAt = this.starttime-5*60;
          //alert( this.printTime(this.starttime));
          for ( var m=this.dayStartAt; m <= this.starttime + this.gridAds.detail.duration*60 + 5*60;)
          {
              let unit = this.unit;
          	  this.grid.push(m);
	          m=m+unit*60;
          }
      },
      fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      },
      mainTime(time) { 
        let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        if ( hh >= 24 )
        {
        	return "height: "+this.zoom+"pt; background-color: #e0e0ff;";
        }
      	return "height: "+this.zoom+"pt;";
      },
      negative(time)
      {
        return ( time < 0 )
      },
      printTime(time,sep,withSec,noHour)
      {
        var negative = false;
        if ( time < 0 )
        {
        	negative = true;
        	time = -time;
        }
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        let hhStr = hhVal + sep;
        if ( noHour )
        {
        	hhStr = "";
        }
        if ( withSec )
        {
        	let ssVal = this.fmtTimePart(ss);
        	return hhStr + mmVal + sep + ssVal;
        }
        if ( negative )
        {
        	return "-" + hhStr + mmVal;
        }                 
        return hhStr + mmVal;
       },
      getBreakCode(adSpace, brk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "OP";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "END";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += "."+brk.shortname;
        code += "@";
      	if ( this.gridAds.category )
      	{
      		code += this.gridAds.category.shortname;
      	}
      	code += this.printTime(this.gridAds.starttime,"");
      	return code;
      },
      printTime2(time)
      {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
        let minusDay=false;
        let plusDay=false;
        if ( time < 0 )
        {
        	time += 24*3600;
        	minusDay = true;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        
        
        if ( hh >= 24 )
        {
        	hh -= 24;
        	plusDay = true;
        }
        
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        //let ssVal = this.fmtTimePart(ss);
        return hhVal +":"+mmVal +((plusDay)?" +1":"")+((minusDay)?" -1":"");
       },
    gridStyle(grid) {
    	var style = "top: ";
    	var height = grid.detail.duration * this.zoom / this.unit; 
    	var start = (grid.detail.starttime-(this.dayStartAt))/60* this.zoom / this.unit
    	style += start+"pt; height: "+height+"pt;";
    	//alert( start + "/"+this.dayStartAt );
    	if ( grid.rateCard )
    	{
    		style += "background-color: "+grid.rateCard.colour+";";
    	}
    	return style;
    },
    adSpaces( grid)
    {
    	if ( !this.withAds  )
    	{
    		//return [];
    	}
    	return grid.adspace;
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    getSpots( )
    {
    	let that = this;
    	if ( !that.placementIDs || !that.placementIDs.length)
        {
        	that.placementIDs = [that.placement.id]	
        }
        if( this.placementIDs.length)
        {
            
        	that.filteredSpots = new Array();
        	//that.spots = new Array();
        	this.startLoader();
        	this.$forceUpdate();
            //alert(this.breakReference);
	        that.invalidSpotsFound = 0;
	        that.allMedia = new Array();
	        that.allPrograms = new Array();
	        that.allPGs = new Array();
	        that.allStatus = new Array();
	        
	        that.allCopies = new Array();
	        that.allProducts = new Array();
	        that.allDurations = new Array();
	        that.allTypes = new Array();
	        that.allSpotReasons = new Array();
	        let time = new Date().getTime();

	        let searchRequest = { placementIds: that.placementIDs, filter: this.spotSearchFilter};
			//alert( JSON.stringify( searchRequest))
			
		        HTTP.post( bngAPI+"/findAllSpotsForPlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, searchRequest) //, that.placement)
	        		 .then( response => 
	                 {  
	                    let dur = (new Date().getTime()-time);
	                    
	                    //console.log(JSON.stringify(response.data[0].spots[0]))
		                //console.log("spot-list: loading grid "+response.data[0].spots.length+" spots in " + dur +"ms");
	                    that.spots = response.data[0].spots;
	                    that.user = response.data[0].user;

	                    that.bookingTypes = response.data[0].bookStatus;
						that.positionRequests = response.data[0].positionReqests;
						that.positionRequests.sort( (a,b)=>a.shortname.localeCompare( b.shortname))
	                    
	                    if ( that.user && that.user.spotListColumns )
	                    {
	                    	that.selected = that.user.spotListColumns.split(',');
	                    }
	                    that.arrangeColumns();
	                    for ( var s in that.spots )
	                    {
	                    	let spot = that.spots[s];
	                    	spot.weekday = ((spot.weekday+1)%7)+1;
	                    	if ( !that.allMedia.includes(spot.channelShort))
	                    	{
	                    		that.allMedia.push(spot.channelShort);
	                    	}
	                    	if ( !that.allPrograms.includes(spot.gridName))
	                    	{
	                    		that.allPrograms.push(spot.gridName);
	                    	}
	                    	if ( !that.allTypes.includes(spot.businessType))
	                    	{
	                    		that.allTypes.push(spot.businessType);
	                    	}
	                    	if ( !spot.pricegroup )
	                    	{
	                    		spot.pricegroup= "--";
	                    	}
	                    	if ( !that.allPGs.includes(spot.pricegroup))
	                    	{
	                    		that.allPGs.push(spot.pricegroup);
	                    	}
	                    	if ( !that.allStatus.includes(spot.statusName))
	                    	{
	                    		that.allStatus.push(spot.statusName);
	                    	}
	                    	if ( !that.allDurations.includes(spot.duration))
	                    	{
	                    		that.allDurations.push(spot.duration);
	                    	}
	                    	if ( !spot.productName )
		                	{
		                		spot.productName= "--";
		                	}
	                    	if ( !that.allProducts.includes(spot.productName))
	                    	{
	                    		that.allProducts.push(spot.productName);
	                    	}
	                    	if ( !spot.copyName )
	                    	{
	                    		spot.copyName= "--";
	                    	}
	                    	if ( !that.allCopies.includes(spot.copyName))
	                    	{
	                    		that.allCopies.push(spot.copyName);
	                    	}
	                    	if ( !spot.reason4Invalid )
	                    	{
	                    		spot.reason4Invalid= "--";
	                    	}
	                    	else
	                    	{
	                    		that.invalidSpotsFound++;
	                    	}
	                    	if ( !that.allSpotReasons.includes(spot.reason4Invalid))
	                    	{
	                    		that.allSpotReasons.push(spot.reason4Invalid);
	                    	}
	                    }

	                    if ( that.allMedia.length == 1 )
	                    {
	                    	that.selectedMedia = that.allMedia;
	                    }
	                    if ( that.allDurations.length == 1 )
	                    {
	                    	that.selectedDurations = that.allDurations;
	                    }
	                    if ( that.allPrograms.length == 1 )
	                    {
	                    	that.selectedPrograms = that.allPrograms;
	                    }
	                    if ( that.allPGs.length == 1 )
	                    {
	                    	that.selectedPGs = that.allPGs;
	                    }
	                    if ( that.allStatus.length == 1 )
	                    {
	                    	that.selectedStatus = that.allStatus;
	                    }
	                    if ( that.allTypes.length == 1 )
	                    {
	                    	that.selectedTypes = that.allTypes;
	                    }
	                    if ( that.allSpotReasons.length == 1 )
	                    {
	                    	that.selectedSpotReasons = that.allSpotReasons;
	                    }
	                    that.doFilterSpots();
	                   
	                    that.doOrderBy();
	                        
	                 }).catch(e => {
	                        //this.error("loading data for client", JSON.stringify(e));
	                        console.log( JSON.stringify(e));
	                        showError(that.$toast, "loading spots", e);
	                    });
		 }
		 else if( this.campaign && this.campaign.id)
         {
            let that = this;
        	that.filteredSpots = new Array();
        	//that.spots = new Array();
        	this.startLoader();
        	this.$forceUpdate();
            //alert(this.breakReference);
	        that.invalidSpotsFound = 0;
	        that.allMedia = new Array();
	        that.allPrograms = new Array();
	        that.allPGs = new Array();
	        that.allStatus = new Array();
	        
	        that.allCopies = new Array();
	        that.allProducts = new Array();
	        that.allDurations = new Array();
	        that.allSpotReasons = new Array();
	       
	        let time = new Date().getTime();
		        console.log(bngAPI+"/findAllSpotsForCampaign/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.campaign.id);
		        HTTP.post( bngAPI+"/findAllSpotsForCampaign/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.campaign.id, that.campaign)
	        		 .then( response => 
	                 {  
	                    let dur = (new Date().getTime()-time);
	                   
	                    console.log(JSON.stringify(response.data[0].spots[0]))
		                //alert("spot-list: loading grid "+response.data[0].spots.length+" spots in " + dur +"ms");
	                    that.spots = response.data[0].spots;
	                    that.user = response.data[0].user;

	                    that.bookingTypes = response.data[0].bookStatus;
	                    
	                    if ( that.user && that.user.spotListColumns )
	                    {
	                    	that.selected = that.user.spotListColumns.split(',');
	                    }
	                    that.arrangeColumns();
	                    for ( var s in that.spots )
	                    {
	                    	let spot = that.spots[s];
	                    	spot.weekday = ((spot.weekday+1)%7)+1;
	                    	if ( !that.allMedia.includes(spot.channelShort))
	                    	{
	                    		that.allMedia.push(spot.channelShort);
	                    	}
	                    	if ( !that.allPrograms.includes(spot.gridName))
	                    	{
	                    		that.allPrograms.push(spot.gridName);
	                    	}
	                    	if ( !spot.pricegroup )
	                    	{
	                    		spot.pricegroup= "--";
	                    	}
	                    	if ( !that.allPGs.includes(spot.pricegroup))
	                    	{
	                    		that.allPGs.push(spot.pricegroup);
	                    	}
	                    	if ( !that.allStatus.includes(spot.statusName))
	                    	{
	                    		that.allStatus.push(spot.statusName);
	                    	}
	                    	if ( !that.allDurations.includes(spot.duration))
	                    	{
	                    		that.allDurations.push(spot.duration);
	                    	}
	                    	if ( !spot.productName )
		                	{
		                		spot.productName= "--";
		                	}
	                    	if ( !that.allProducts.includes(spot.productName))
	                    	{
	                    		that.allProducts.push(spot.productName);
	                    	}
	                    	if ( !spot.copyName )
	                    	{
	                    		spot.copyName= "--";
	                    	}
	                    	if ( !that.allCopies.includes(spot.copyName))
	                    	{
	                    		that.allCopies.push(spot.copyName);
	                    	}
	                    	if ( !spot.reason4Invalid )
	                    	{
	                    		spot.reason4Invalid= "--";
	                    	}
	                    	else
	                    	{
	                    		that.invalidSpotsFound++;
	                    	}
	                    	if ( !that.allSpotReasons.includes(spot.reason4Invalid))
	                    	{
	                    		that.allSpotReasons.push(spot.reason4Invalid);
	                    	}
	                    }

	                    if ( that.allMedia.length == 1 )
	                    {
	                    	that.selectedMedia = that.allMedia;
	                    }
	                    if ( that.allDurations.length == 1 )
	                    {
	                    	that.selectedDurations = that.allDurations;
	                    }
	                    if ( that.allPrograms.length == 1 )
	                    {
	                    	that.selectedPrograms = that.allPrograms;
	                    }
	                    if ( that.allPGs.length == 1 )
	                    {
	                    	that.selectedPGs = that.allPGs;
	                    }
	                    if ( that.allStatus.length == 1 )
	                    {
	                    	that.selectedStatus = that.allStatus;
	                    }
	                    if ( that.allSpotReasons.length == 1 )
	                    {
	                    	that.selectedSpotReasons = that.allSpotReasons;
	                    }
	                    that.doFilterSpots();
	                   
	                    that.doOrderBy();
	                        
	                 }).catch(e => {
	                        //this.error("loading data for client", JSON.stringify(e));
	                        console.log( JSON.stringify(e));
	                        
	                    });
		 }
    },
    removeDeleted( deleted)
    {	
    	let ids = new Array();
    	for ( var x in deleted )
    	{
    		ids.push( deleted[x].id);
    	}
    	let tmp = new Array();

    	for ( var s in this.spots )
        {
        	let spot = this.spots[s];
        	if ( !ids.includes(spot.id) )
        	{
        		tmp.push(spot);
        	}
        	
        }
        this.spots = tmp;
        this.doFilterSpots();
	    this.doOrderBy();
    },
    updateSpots( updated)
    {	
    	let ids = new Array();
    	for ( var x in updated )
    	{
    		ids.push( updated[x].id);
    	}
    	
    	for ( var s in this.spots )
        {
        	let spot = this.spots[s];
        	if ( ids.includes(spot.id) )
        	{
        	    let repl = updated.find( x => x.id == spot.id);

        		Object.assign( this.spots[s], repl);
        	}
        	
        }
        //this.doFilterSpots();
	    //this.doOrderBy();
    },
    adLabel(ad) {
        var text = "";
        if ( ad.section == 0 )
        {
        	text = "OPENER";
        }
        else if ( ad.section == 2 )
        {
        	text =  "CLOSER";
        }
        else
        {
        	text =  "CENTER-"+ad.no;
        }
        return text;
    },
    adStyle(grd, ad) {
            	
    	var height = 6 * this.zoom / this.unit; 
    	if ( !this.withAds  )
    	{
    		return "background-color: transparent; color: transparent;";
    	}
    	if ( height > 16 )
    	{
    		height = 14;
    		
    	}
    	var fontSize = 1+height/2;
    	if ( fontSize < 7 )
    	{
    		fontSize = 7;
    	}
    	var start = ad.estStarttime;
    	
    	start = ((start - grd.detail.starttime))/60* this.zoom / this.unit;
    	if ( ad.section == 0 )
        {
        	start = -height
        } 

    	var style =  "top: " + start+"pt; font-size: "+fontSize+"pt;";
    	style += "border-top: .5pt solid #aaa;";
    	//console.log( grd.detail.name +"-"+ad.no+"/"+ad.estStarttime+"/"+grd.detail.starttime+"/"+this.dayStartAt+" -> "+style);
    	return style;
    },
    startDrag (evt, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('spotIndex', idx)
  	},
  	startDragColumn (evt, col, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
  	deleteSpotArr(toDelete)
  	{
  		this.$emit("deleteSpot", toDelete);
  	},
  	deleteSpot(spot)
  	{
		try {
        	this.$refs.menu.close();
        } catch (e) { 
        //
        } 
		var toDelete = new Array();
		if ( spot) 
		{
			toDelete.push(spot.id);
		}
		else
		{
     
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				toDelete.push(spot.id);
	     				break;
	     			}
	     		}
	     	}
	    }
  		this.deleteSpotArr( toDelete);
  	},
	removeColumn (evt )
	{
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		if ( columnIndex && columnIndex >= 0 ) 
		{
			this.selectedColumns.splice( columnIndex, 1);
		}
		this.calcWidths( true );
	},
	onDropColumn (evt, target) {
		const source = evt.dataTransfer.getData('column');
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		var colToInsert;
		for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i];
     		if ( col.show == source )
     		{
     			colToInsert = col;
     			break;
     		}
     	}
     	if ( colToInsert )
     	{
	     	//alert( target + " vs " + columnIndex);
	     	if ( target <= columnIndex )
	     	{
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
				this.selectedColumns.splice( target, 0, colToInsert);
			}
			else
			{
				this.selectedColumns.splice( target+1, 0, colToInsert); // insert at 6
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
			}
			
			this.calcWidths( true );
		}
	},
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
	     	var saveUser = 0;
	     	if ( this.user && ! this.user.spotListColumns)
	     	{
	     		saveUser = 1;
	     		this.user.spotListColumns=columns;
	     	}
	     	if ( this.user && this.user.spotListColumns != columns)
	     	{
	     		saveUser = 1;
	     		this.user.spotListColumns=columns;
	     	}
	     	if ( saveUser )
	     	{
	     		this.$emit("saveUser", this.user);
	     	}
		}
     	this.widthCalculated = wTot;
	},
	
	arrangeColumns()
	{
	    this.selectedColumns = new Array();
		for ( var i in this.selected )
		{
			let colName = this.selected[i];
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.name == colName )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	addColumn (show) {
		if ( this.avColumnsVisible )
		{
			var colToInsert;
			for ( var i in this.availableColumns )
	     	{
	     		let col = this.availableColumns[ i];
	     		if ( col.show == show )
	     		{
	     			colToInsert = col;
	     			break;
	     		}
	     	}
	     	this.selectedColumns.splice( this.selectedColumns.length-1, 0, colToInsert); // insert at 6
	     	
			this.calcWidths( true);
		}
	},
	parseWeekdays()
	{
		//alert( JSON.stringify(this.inputWeekdays ));
	    this.selectedWeekdays = new Array();
	    var p2 = 1;
        for ( var ii = 0; ii < 7; ii++ )
        {
            this.selectedWeekdays[ii] = (this.inputWeekdays & p2) == p2;
            p2 = p2 * 2;
        }
        //alert( JSON.stringify(this.selectedWeekdays ));
		this.doFilterSpots();
		this.doOrderBy();
	},
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  watch: {
	time: function() { if ( this.placement) { this.getSpots();} },
	deletedSpots: function() { this.removeDeleted( this.deletedSpots); },
	updatedSpots: function() { this.updateSpots( this.updatedSpots); },
	
	inputDateFrom: function() {
	        this.selectedDateFrom = new Date(this.inputDateFrom);

	        //alert( this.selectedDateFrom);
	        this.doFilterSpots();
			this.doOrderBy();
			this.$forceUpdate();
			},
    inputDateTo: function() {
            this.selectedDateTo = new Date(this.inputDateTo);
	        this.doFilterSpots();
			this.doOrderBy();
			this.$forceUpdate();
			},
	inputWeekdays: function() {
	    this.parseWeekdays();
		this.$forceUpdate();
	},
	inputBreakTypes: function()  { 
			this.selectedBreakTypes = new Array();
			for ( var i in this.inputBreakTypes ) 
			{
				let bType = this.inputBreakTypes[i];
				this.selectedBreakTypes.push(bType.label);
			}

			this.doFilterSpots();
			this.doOrderBy();
			this.$forceUpdate();
		}
  },
  created() {
	 if ( this.placement) { 
	 	this.weekdayArray = JSON.parse(sessionStorage.weekdays); 
	 	this.parseWeekdays(); 
	 	this.selectedDateFrom = new Date(this.inputDateFrom);
	 	this.selectedDateTo = new Date(this.inputDateTo);
	 	this.getSpots();
	 } 
	 if ( this.preSelectMedia) { this.selectedMedia = this.preSelectMedia}
  },
  beforeUpdate() {
  	
  },
  updated() {
  //console.log("update...");
  this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been re-rendered
    //console.log("stopping loader");
    if (this.loadingActive)
    {
        //console.log("stop loader: " + timers.length)
	    
	    timers.push( setTimeout( this.stopLoader, 100));
    }
  })
}
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  color: #000;
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}
.spotDetail { 
  display: flex;
  align-items: center;
  
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1px solid #eee; 
  border-bottom: 1pt solid #ccc;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-o-user-select: none;
user-select: none;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 
.modal-mask {
  //position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {

  height: 100%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  height: 100%;
}
.displayRed {
	color: #000;
	background-color: #fcc;
	border-bottom: 1pt double red;
}
.modal-default-button {
  float: right;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.gridMainBV { position: absolute; z-Index: 2; text-align: center; width: 99% ; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
.adMainBV { 
  float: top;
  position: absolute; 
  align-items: center;
  justify-content: center;
  z-Index: 17; 

  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 
  border: .5pt solid black;
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
}
.adMainInnerRight { 
  color: #000; 
  width: 100%;
  text-align: left !important;
  //border-bottom: .5pt solid #ddd;
  padding-left: 5pt;
  background-color: rgba(255, 255, 255, .6);
  //border-left: .5pt solid #ddd;
    opacity: 1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.adMainInnerLeft { 
  align-items: center;
  justify-content: center;
  float: left top;
  z-Index: 17; 
  padding-top: 2pt;
  font-size: 9pt;
  background-color: rgba(255, 255, 255, .6);
  font-weight: bold;
  margin-left: 2pt; 
  margin-right: 4pt; 
  color: #666; 
  height: 100% !important;
  
    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
.bold {
  font-weight: normal;
}

.smallText {
  font-size: 9pt;
  height: 12pt;
}
.light {
	color: #888;
}
.right {
	text-align: right;
}
.reqLineBG { 
  background-color: #ccc !important; 
}
.myButtonSmall {
	//width: 18%;
    padding: 3px 4px;
    outline: none;
    border-radius: 4px 12px 0 0;
    height: 20pt;
    font-size: 7pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 4pt;
} 
</style>
