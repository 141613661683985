<template>
 <div >

     <XModal name="xb2" :resizable="true"  width='66%' height="60%" :draggable="true" @before-close="onClose">
       <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
         <div class='appHead'>
             <div style='display: inline-flex; color: #444; font-weight: bold; padding-left: 5pt; padding-top: 3pt; font-size: 10pt; float: left;'>
             <span>Briefing summary</span> 
             </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       
        <div v-if="ready" class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent dontWrap'  >
                <div :style="getAppStyle()" class="header">{{uploadData.name}}</div>
             	
			    <div style='width: 100%; height: calc(55vH); overflow-y: auto;'>
					<div class="row" style="display: block; width: 100%;">
					Detected format: <b>{{uploadData.type}}</b>
					
					</div>
					<div class="row">
					Advertiser: <b>{{uploadData.client}}</b>, Product: <b>{{uploadData.product}}</b>
					</div>
					<div class="row">
					Period: <b>{{uploadData.from}} - {{uploadData.to}}</b>, Channel: <b>{{channel()}}</b>
					</div>
					
					<div class="row">
					{{tr("Placement")}}: {{placement.name}} 
					</div>
					<div class="row">
					Advertiser: {{uploadData.clientPlacement}}, Product: {{uploadData.productPlacement}} <span v-if="placementFits" style='color: green'><mdicon name="check"/></span>
					</div>
					
					<div class="row">
					Channel: <span :style="channelFits()?'':'color: red; font-size: bold;'">{{placementChannel()}}</span> <span v-if="channelFits()" style='color: green'><mdicon name="check"/></span>
					</div>
					<div class="row">
					Duration: <span :style="durationFits()?'':'color: red; font-size: bold;'">{{placement.duration}}"</span> <span v-if="durationFits()" style='color: green'><mdicon name="check"/></span>
					</div>
					
					<div class="row">
					Period: <span :style="uploadData.periodMatch?'':'color: red; font-size: bold;'">{{placement.fromDate}} - {{placement.untilDate}}</span> <span v-if="uploadData.periodMatch" style='color: green'><mdicon name="check"/></span>
					</div>
					
				
					<div>
					<div class='row' style='display: inline-flex; padding-top: 2pt; padding-right: 4pt;'> {{tr("Client")}}/{{tr("Product")}} fits to {{tr("Placement")}}:</div>
					<div class='rowNH' style='display: inline-flex; position: top; vertical-align: top;'><app-switch v-model="placementFits" :checked="placementFits"/></div>
					
					</div>
					
					<br/>
					<table border=1 fixed>
					<tr>
						<td class="title">Channel</td><td class="title">Duration</td><td class="title">Matching lines</td><td class="title">Not fitting lines</td>
						<td class="title">Max total freq</td>
						<td class="title">&Sigma; Paid (req)</td><td class="title">&Sigma; Paid (booked)</td>
						<td class="title">&Sigma; Free (req)</td><td class="title">&Sigma; Free (booked)</td>
						<td class="title">Campaign free %</td>
						<td class="title">{{tr("Placement")}}</td>
					</tr>	
					<slot v-for="ch in uploadData.medias">					
					<tr v-for="dur in new Set(uploadData.lines.filter(p=>p.channel===ch).map(p=>p.duration))" :key="'row'+dur+ch">
						
						
						<td class="right" :style="(foundPlacements && placement && plcm(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)))==placement.id)?'font-weight: bold;':''">{{ch}}</td>
						<td class="right">{{dur}}"</td>
						<td class="right">{{uploadData.validLines.filter(p=>p.channel===ch  && p.duration===dur).length}}</td>
						<td class="right">{{uploadData.invalidLines.filter(p=>p.channel===ch && p.duration===dur).length}}</td>
						<td class="right">{{fmt((uploadData.lines.filter(p=>p.channel===ch && p.duration===dur).map(p=>p.freq)).reduce((s, a) => s + a, 0))}}</td>
						<td class="right">{{fmt((uploadData.lines.filter(p=>p.channel===ch && p.duration===dur).map(p=>p.gross)).reduce((s, a) => s + a, 0))}}</td>
						<td class="right">{{fmt(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)).gross)}}</td>	
						<td class="right">{{fmt((uploadData.lines.filter(p=>p.channel===ch && p.duration===dur).map(p=>p.frees)).reduce((s, a) => s + a, 0))}}</td>
												
						<td class="right">{{fmt(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)).free)}}</td>
						<td class="right">{{fmtCampFR(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)).campFR)}}</td>			
						<td class="center" style="cursor:pointer; text-align: left;"  
							v-if="foundPlacements.length && plcm(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)))">
							<button disabled="true" class="button" style='height: 16pt;' :title="plcmName(foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)))">
								<mdicon :name="placementFits?'check':'cancel'"/></button>
								<!-- v-show="placement.id!==foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)).placement.id"-->
							<button  :disabled="!placementFits" class="button" style='height: 16pt;'
								    title="switch to this placement in the traffic manager"
							        @click="openPlacement( foundPlacements.find(p=>p.channel===ch && p.duration === parseInt( dur)).placement)">
								<mdicon name="swap-horizontal"/></button>
						</td>
						<td class="center" v-else>
							<button :disabled="!placementFits" title="create new placement" class="button" style='height: 16pt;'
							@click="addPlacement(ch,dur, )"><mdicon name="new-box"/></button>
							<button :disabled="!placementFits" title="adjust this placement" class="button" style='height: 16pt;'
							@click="adjustPlacement(ch,dur, )"><mdicon name="tune"/></button>
						</td>
								
					</tr>
					</slot>
					</table>
					
					<button :disabled="reloaded" class="button" title="reload input file" :style="getAppStyle()+'margin-left: 5pt; margin-top: 5pt; margin-bottom: 5pt; height: 24pt;'" @click='$emit("reloadBriefing")'><mdicon :width="20" name="reload" /></button>	
					
					<br/>
					<!--
					<b>Findings:</b>
					<div class="row" v-for="(err,i) in uploadData.errors" :key="'er'+i">
						<div>
							{{err}}: {{uploadData.invalidLines.filter(p=>p.error===err).length}} (Channel: {{new Set(uploadData.invalidLines.filter(p=>p.error===err).map(p=>p.channel)).join()}}) 
						</div>
					</div>
					-->
					<div>
						
					
			        </div>
		    	</div>  <!-- TABLE -->
            </div> <!-- float: left -->
            </slot>
			</div> <!-- mainContent -->
	      
	    </div> <!-- modal-body -->
	    
	
	  </XModal> <!-- modal-container -->  	  
	
	<ContextMenu ref="menu" offsetY=120>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem >
          What ever
        </ContextMenuItem>
        <ContextMenuItem >
          What else
        </ContextMenuItem>
        <ContextMenuSep/>
        
       </template>
    </ContextMenu>
	<GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
 </div>
</template>

<script>
import {HTTP, fwAPI, placementServiceAPI, bngAPI, setReload, showError, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle, initAppMode, isDarkMode } from '@/AppStyle.js';
import { format, formatNZ } from '@/Numbers.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import { tr } from '@/translate.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW DEAL View',
  components : {
   ContextMenu,ContextMenuItem,ContextMenuSep, 'app-switch': Switch
  },
  props: {

  },
  data () {
    return {
    	data: {},
    	uploadData: {},
		placement: {},
		briefingOpt: {},
    	getAppStyle, format, formatNZ, tr,
    	ready: false,
		foundPlacements: [],
		placementFits: null,
		reloaded: true
      }
  },
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
    
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
	plcm( p)
	{
		if ( p && p.placement)
		{
			return p.placement.id
		}
		return "";
	},
	plcmName( p)
		{
			if ( p && p.placement)
			{
				return p.placement.name
			}
			return "";
		},
    openPlacement( p)
	{
		this.placement = p;
		this.reloaded = false;
		this.$emit( "openPlacement", p);
	},
	durationFits()
	{
		return this.uploadData.durations.includes( parseInt(this.placement.duration));
	},
	channel()
	{
		return new Set(this.uploadData.validLines.map(p=>p.channel)).join();
	},
	placementChannel()
	{
		return this.uploadData.mediaPlacement;
	},
	channelFits()
	{
		let check = parseInt(new Set(this.uploadData.validLines.map(p=>p.channelId)).join());
		
		return this.placement.media.includes(check);
	},
	findPlacements( uploadData)
	{
		let that = this;
		let list = []; 
		
		for (let ch in uploadData.medias)
		{
			let channel = uploadData.medias[ch];
			let durations = [... new Set( uploadData.lines.filter(p=>p.channel===channel).map(p=>p.duration))];
			
			for ( let dur in durations)
			{
				let duration = durations[dur];
				list.push( {channel: channel, duration: duration});
			}
		}					
		//alert( placementServiceAPI+"/findPlacements/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.campaignId+"/"+uploadData.from+"/"+uploadData.to);
		HTTP.post( placementServiceAPI+"/findPlacements/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+uploadData.from+"/"+uploadData.to, list)
        .then( response => {
			//alert( JSON.stringify( response.data))
        	that.foundPlacements = response.data.list;
			that.placementFits = uploadData.placementFits?true:false;
			
        }).catch(e => {
                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
        });
							
	},
	addPlacement( channel, dur)
		{
			let that = this;
			let relevantLines = this.uploadData.lines.filter(p=>p.channel===channel && p.duration===dur)		
			let gross = Math.round((relevantLines.map(p=>p.gross)).reduce((s, a) => s + a, 0));
			
			let request = { channel: channel, name: that.uploadData.campaignName, duration: dur, gross: gross, briefingOpt: this.briefingOpt, template: this.placement, relevantLines: relevantLines}; 
			
			HTTP.put( placementServiceAPI+"/addPlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.campaignId+"/"+this.placement.productId+"/"+this.uploadData.from+"/"+this.uploadData.to, request)
	        .then( response => {
				let info = response.data;
				
				that.foundPlacements = that.foundPlacements.filter(p=>!(p.channel===channel && p.duration === dur));	
	        	that.foundPlacements.push( info);
	        }).catch(e => {
	                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	        });
						
		},
		adjustPlacement( channel, dur)
		{
			let that = this;
			let relevantLines = this.uploadData.lines.filter(p=>p.channel===channel && p.duration===dur)		
			let gross = Math.round((relevantLines.map(p=>p.gross)).reduce((s, a) => s + a, 0));
			let request = { channel: channel, name: that.uploadData.campaignName, duration: dur, gross: gross, briefingOpt: this.briefingOpt, template: this.placement, relevantLines: relevantLines}; 
			that.foundPlacements = that.foundPlacements.filter(p=>!(p.channel===channel && p.duration === dur));
			that.foundPlacements.push({channel: channel,  duration: dur, gross: gross, placement: this.placement});	
		    
			HTTP.put( placementServiceAPI+"/adjustPlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.campaignId+"/"+this.placement.productId+"/"+this.uploadData.from+"/"+this.uploadData.to, request)
	        .then( response => {
				let info = response.data;
				that.placement = info.placement; 
				that.reloaded = false;
				that.findPlacements( that.uploadData);
				//that.foundPlacements = that.foundPlacements.filter(p=>!(p.channel===channel && p.duration === dur));	
				//that.foundPlacements.push( info);
	        }).catch(e => {
	                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	        });
						
		},	
	fmt( val)
	{
		return formatNumber( val);
	},
	fmtCampFR( val)
	{
		if ( val === 100)
		{
			return "?";
		}
		return this.fmt( val*100);
	},
	onClose()
	{
		this.uploadData.placementFits = this.placementFits;
	},
    open(data, plcm, briefingOpt) { 
         this.placement = plcm;
		 this.briefingOpt = briefingOpt;
     	 this.uploadData = data;
		 this.placementFits = data.placementFits?true:false;
		 this.reloaded = true;
		 this.findPlacements( data);
		 //alert( this.placement.duration)
		 this.$modal.show('xb2');
		 
		 this.ready = true;
    
     },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
  
     identity(x) { return x },
    
     close() { this.$modal.hide('xb2') },
     
  },
  watch: {

	
  },
  created() {
	this.$modal.show('x2');
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }

.right {
	align-items: right;
	text-align: right;
	justify-content: right;
	padding-left: 5pt;
}

.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important; 
  border-bottom: 1pt solid #ccc;
  
}
.header { 
  font-wight: bold;
  font-size: 11pt; 
  padding: 2pt 2pt 2pt 2pt;
}
.cell
{
	font-size: 10pt; 
}
.title
{
	display: table-cell; 
	font-size: 9pt; 
	height: 18pt;
	width: 80pt !important;
	padding-top: 2pt;
	padding-bottom: 2pt;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
	background-color: #3b60c4;
	color: #fff;
}
.indent {
	padding-left: 5pt;
	font-style: italic;
	font-weight: normal;
}
.cumulative {
	font-style: italic;
	font-weight: normal;
}
.rowNH
{
	display: table-row;
	font-size: 10pt;
}
.row
{
	display: table-row;
	font-size: 10pt;
}
.row:hover
{
	background-color: #777;
	color: #fff;
}
.gap5 
{
	border-top: 2px solid #888;
}
.head
{
	font-weight: bold;
	background-color: #3b60c4;
	color: #fff;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.center {
  text-align: center;
}
.left {
  justify-content: left;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  x-overflow: hidden;
}

.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}

.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
    
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
