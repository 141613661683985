 <template>
   <div>
    <div v-if="simple">
       <div v-for="(cap, capi) in cappings" :key="'cp'+capi" class="transition" >
         <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
			   <span class='smallText bold'>{{cap.name}}:</span>
			   
			   <div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>    
				    <InputField v-model="cap.capping" @input="update"/>
				    <button :disabled="!cap.capping" @click="minus(cap)" class="button" :style="getAppStyle()+'height: 22pt; width: 22pt;'"><mdicon name="minus" width="12pt"/></button>
				    <button @click="plus(cap)" class="button" :style="getAppStyle()+'height: 22pt; width: 22pt;'"><mdicon name="plus" width="12pt"/></button>
				    <button :disabled="!cap.capping" @click="clear(cap)" class="button" :style="getAppStyle()+'height: 22pt; width: 22pt;'"><mdicon name="close" width="12pt"/></button>
			   </div>
		 </div>
		</div>
		
    </div>
    <div v-else-if="isPacked" style='border: 1px solid #ccc; width: 90%; height: 22pt;' @click="isPacked=false" class="transition">
       <span v-for="(cap, capi) in cappings" :key="'cp'+capi" class="capps">
       <span v-if="cap.capping" class="cappsOn">
       {{cap.name}}={{cap.capping}}
       </span>
       <span v-else class="cappsOff">
       {{cap.name}}
       </span>
       
       </span>
    </div>
    <div v-else v-click-outside="close"> 
    
       <div style='border: 1px solid #ccc; width: 90%; height: 22pt;' @click="isPacked=!isPacked" class="transition">
       <span v-for="(cap, capi) in cappings" :key="'cp'+capi" class="capps">
       <span v-if="cap.capping" class="cappsOn">
       {{cap.name}}={{cap.capping}}
       </span>
       <span v-else class="cappsOff">
       {{cap.name}}
       </span>
       
       </span>
    </div>
    <div  :style="getAppStyle()+'position: absolute; width: 60%; z-index: 999; padding: 5pt; box-shadow: 0 4px 4px rgba(0, 0, 0, 0.33); border: 1px solid #ccc;'">
       <div v-for="(cap, capi) in cappings" :key="'cp'+capi" class="transition" >
         <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
			   <span class='smallText bold'>{{cap.name}}:</span>
			    
			   <div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;' >
			    	    
				    <InputField v-model="cap.capping" @input="update"/>
				    <button :disabled="!cap.capping" @click="minus(cap)" class="button" style='height: 22pt; width: 22pt;'><mdicon name="minus" width="12pt"/></button>
				    <button @click="plus(cap)" class="button" style='height: 22pt; width: 22pt;'><mdicon name="plus" width="12pt"/></button>
				    <button :disabled="!cap.capping" @click="clear(cap)" class="button" style='height: 22pt; width: 22pt;'><mdicon name="close" width="12pt"/></button>
			   </div>
		 </div>
		</div>
	</div>
  </div>
  
  </div>
</template>

<script>

import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputField from '@/components/booking/InputField';
import ClickOutside from 'vue-click-outside'
export default {
  name: "CAPPINGS",
   components : {
	   InputField
  },
  props: {
	  cappingUnits: Array,
	  simple: { type: Boolean, default: true},
	  packed: { type: Boolean, default: false},
  },
  data(){
    return {
    	cappings: [],
    	getAppStyle, getBGStyle2, getBGStyle3,
    	isPacked: this.packed
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
    	clear(cap)
    	{
    		delete cap.capping;
    		this.$forceUpdate();
    		this.update();
    	},
    	minus(cap)
    	{
    		if ( !cap.capping)
    		{
    			cap.capping = 1;
    		}
    		cap.capping--;
    		if ( !cap.capping)
    		{
    			delete cap.capping;
    		}
    		this.$forceUpdate();
    		this.update();
    	},
    	plus(cap)
    	{
    		if ( !cap.capping)
    		{
    			cap.capping = 0;
    		}
    		cap.capping++;
    		this.$forceUpdate();
    		this.update();
    	},
    	close()
    	{
    		if ( !this.isPacked)
    		this.isPacked = true;
    	},
    	update()
    	{
    		this.$emit("input", this.cappings)
    	},
        sort( units)
        {
        	units = units.sort((a,b)=>a.name.localeCompare(b.name));
        	return units;
        }
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  	 this.cappings = [...this.cappingUnits];
  	 this.sort( this.cappings)
  },
   watch:
   {
   	   //value: function() { this.$emit("input", this.value)}
   }
}
</script>
<style  scoped>

.dontWrapIB {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
  height: 23pt;
  padding-top: 1pt;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.cappsOn {
    background-color: #efe;
    color: #444;
    font-size: 9pt;
    height: 16pt;
	border-radius: 4px;
	margin-right: 5pt;
	margin-left: 5pt;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.33);
}
.cappsOff {
    background-color: #eef;
    color: #444;
    font-size: 9pt;
    height: 16pt;
	border-radius: 4px;
	margin-right: 5pt;
	margin-left: 5pt;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.33);
}
.bold {
  font-weight: bold;
}
 .transition {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
</style>